import {Box, Select, Tooltip} from '@components/mantine';
import {AccessLevel} from '@core/api';
import {FunctionComponent} from 'react';
import {useGuard} from '../../hooks';
import {Locales} from '../../strings/Locales';
import {useAccessTableContext} from './AccessTable.context';

interface AccessTableAccessLevelCellProps {
    id: string;
    accessLevel: AccessLevel;
    type: 'group' | 'apiKey';
}

export const AccessTableAccessLevelCell: FunctionComponent<AccessTableAccessLevelCellProps> = ({
    id,
    accessLevel,
    type,
}) => {
    const {canEdit} = useGuard();
    const {form, getStyles} = useAccessTableContext();
    const rootString = type === 'group' ? 'GroupsAccessTable' : 'APIKeysAccessTable';
    if (accessLevel === AccessLevel.NONE) {
        return (
            <Tooltip label={Locales.format(`${rootString}.view.tooltip`)} position="top-start">
                <Box> - </Box>
            </Tooltip>
        );
    }

    if (accessLevel === AccessLevel.EDIT_ALL) {
        return (
            <Tooltip label={Locales.format(`${rootString}.edit.tooltip`)} position="top-start" maw={220}>
                <Box>{Locales.format('AccessTable.edit')}</Box>
            </Tooltip>
        );
    }

    if (!canEdit) {
        return (
            <Box>
                {Locales.format(
                    form.getValues().data[id] === AccessLevel.VIEW_ALL ? 'AccessTable.view' : 'AccessTable.edit',
                )}
            </Box>
        );
    }

    return (
        <Select
            w={130}
            {...getStyles('accessLevelSelect')}
            data={[
                {value: AccessLevel.VIEW_ALL, label: Locales.format('AccessTable.view')},
                {value: AccessLevel.EDIT_ALL, label: Locales.format('AccessTable.edit')},
            ]}
            {...form.getInputProps(`data.${id}`)}
            key={form.key(`data.${id}`)}
            disabled={!canEdit}
        />
    );
};
