import {Resource} from '@coveo/platform-client';

interface ReactivateUserParams {
    username: string;
    daysToExpiry: number;
}

export class UAReadReaderAccountResource extends Resource {
    static baseUrl = '/rest/ua/v15/admin';

    /**
     * Reactivate a Reader Account user.
     */
    reactivateUser(username: string, params: ReactivateUserParams) {
        return this.api.put<void>(
            this.buildPathWithOrg(`${UAReadReaderAccountResource.baseUrl}/snowflake/users/${username}/expiration`),
            params,
        );
    }

    /**
     * Build the request path, handling the optional `org` query parameter.
     *
     * @param route The path part of the request.
     * @param queryParams Optional query parameters object.
     * If this object contains an `org` property, it will override the value from the configuration.
     * @returns The request path including formatted query parameters.
     */
    protected buildPathWithOrg(route: string, queryParams?: any): string {
        return super.buildPath(route, {org: this.api.organizationId, ...queryParams});
    }
}
