import {Config} from '@core/configuration';
import {CurrentOrganization} from '@core/organization';
import {Locales} from '../strings/Locales';

const urlHash = '#/' + CurrentOrganization.getId();

const appsBaseUrl: Record<AppName, string> = {
    platform: Config.CloudPlatform.url,
    ['knowledge-hub']: Config.CloudPlatform.knowledgeUrl,
    ['commerce-hub']: Config.CloudPlatform.commerceUrl,
};

const getAppUrl = (app: AppName): string => {
    const url = new URL(appsBaseUrl[app]);
    if (app === 'platform') {
        url.pathname = '/admin' + url.pathname;
    }
    url.hash = urlHash;
    return url.toString();
};

const appURL: AppURL = {
    adminConsoleUrl: getAppUrl('platform'),
    knowledgeHubUrl: getAppUrl('knowledge-hub'),
    commerceHubUrl: getAppUrl('commerce-hub'),
};

export const applicationsMap: ApplicationsMap = {
    platform: {
        label: Locales.format('AppSwitcher.administrationConsole'),
        href: appURL.adminConsoleUrl,
    },
    'commerce-hub': {
        label: Locales.format('AppSwitcher.merchandisingHub'),
        href: appURL.commerceHubUrl,
    },
    'knowledge-hub': {
        label: Locales.format('AppSwitcher.knowledgeHub'),
        href: appURL.knowledgeHubUrl,
    },
};
