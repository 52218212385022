import {LaunchDarklyFeatureFlags, LDProject} from '@core/feature-flags';
import {UserSelectors} from '@core/user';
import {createSelector} from 'reselect';

const userCanViewMerchHub = createSelector(
    UserSelectors.getPrivilegesValidator,
    (user) => user.canViewMerchandisingHub,
);

const canAccessMerchHub = createSelector(
    userCanViewMerchHub,
    (canView) => LaunchDarklyFeatureFlags.isActive('merchandising-hub-app') && canView,
);

const userCanViewKnowledgeHub = createSelector(UserSelectors.getPrivilegesValidator, (user) => {
    const isEnabledAnswerAPI = LaunchDarklyFeatureFlags.isActive('enable-answer-api', LDProject.CUSTOMER_SERVICE);
    return isEnabledAnswerAPI && user.canViewKnowledgeHub;
});

const canAccessKnowledgeHub = createSelector(userCanViewKnowledgeHub, (canView) => canView);

const canViewPlatform = createSelector(UserSelectors.getPrivilegesValidator, (user) => user.canViewOrganization);

const canAccessPlatform = createSelector(canViewPlatform, (canView) => canView);

export const AppSwitcherSelectors = {
    canAccessAppSelectors: {
        platform: canAccessPlatform,
        'commerce-hub': canAccessMerchHub,
        'knowledge-hub': canAccessKnowledgeHub,
    },
};
