import {AccessLevel, GroupAccessModel} from '@core/api';
import {AccessTableData} from '../AccessTable.types';

const isPartOfGroup = (groupAccessModels: GroupAccessModel[], groupId: string): boolean =>
    groupAccessModels.find((accessModel) => accessModel.id === groupId && accessModel.callerPartOf) !== undefined;

const isPartOfGroupWithFullEditAccess = (groupAccessModels: GroupAccessModel[]): boolean => {
    const editGroupsPartOf: GroupAccessModel[] = groupAccessModels.filter(
        (accessModel) => accessModel.accessLevel === AccessLevel.EDIT_ALL && accessModel.callerPartOf,
    );

    return editGroupsPartOf.length > 0;
};

const deserializeGroupPrivilegesThatCanEdit = (
    accessModel: GroupAccessModel[],
    resourceId: string,
    userCanEditAll: boolean,
): AccessTableData => {
    const isNew = (resourceId || '') === '';
    const partOfGroupWithFullEditAccess = isPartOfGroupWithFullEditAccess(accessModel);

    return (accessModel || [])
        .filter(({accessLevel}) => [AccessLevel.CUSTOM, AccessLevel.VIEW_ALL].includes(accessLevel))
        .map(({id, resourceIdsWithEditLevel, callerPartOf}) => ({
            [id]:
                (resourceIdsWithEditLevel || []).includes(resourceId || '') ||
                (isNew && !userCanEditAll && !partOfGroupWithFullEditAccess && callerPartOf)
                    ? AccessLevel.EDIT_ALL
                    : AccessLevel.VIEW_ALL,
        }))
        .reduce((acc, value) => ({...acc, ...value}), {});
};

export const GroupsAccessTableUtils = {
    isPartOfGroup,
    deserializeGroupPrivilegesThatCanEdit,
};
