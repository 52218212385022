import Registry from '@core/registry';

import {LaunchDarkly, LaunchDarklyProject} from './LaunchDarkly';

export enum LDProject {
    SEARCH_PAGES,
    PLATFORM,
    CUSTOMER_SERVICE,
    SEARCH_INTERFACES,
}

export const LaunchDarklyFeatureFlags = {
    isActive: (flag: string, project: LDProject = LDProject.PLATFORM): boolean => {
        try {
            const launchDarkly: LaunchDarkly = Registry.get('launchDarkly');
            let ldProject: LaunchDarklyProject;
            switch (project) {
                case LDProject.CUSTOMER_SERVICE:
                    ldProject = launchDarkly?.customerService;
                    break;
                case LDProject.SEARCH_PAGES:
                    ldProject = launchDarkly?.searchPages;
                    break;
                case LDProject.SEARCH_INTERFACES:
                    ldProject = launchDarkly?.searchInterfaces;
                    break;
                case LDProject.PLATFORM:
                default:
                    ldProject = launchDarkly?.platform;
                    break;
            }
            return Boolean(ldProject?.checkFeatureFlag(flag));
        } catch (e) {
            return false;
        }
    },
};
