import {API, Resource} from '@coveo/platform-client';
import {
    DataServiceGetTablesResponse,
    DataServiceQuery,
    DataServiceQueryResponse,
    DataServiceQueriesResponse,
    FieldType,
} from '../interfaces/DataServiceInterfaces';

const patchAbort = async <T>(fetchPromise: Promise<T>, signal?: AbortSignal | null): Promise<T> => {
    const value = await fetchPromise;
    if (value === undefined) {
        // It is possible platform-client aborted via abortGetRequests().
        // In that case the reason can't be accessed; new an Error instead.
        throw signal?.reason || new Error('Request was aborted');
    }
    return value;
};

export class DataServiceResource extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/data/v1`;

    query<T extends readonly FieldType[]>(body: DataServiceQuery<T>, args?: RequestInit) {
        return patchAbort(
            this.api.post<DataServiceQueryResponse<T>>(
                super.buildPath(`${DataServiceResource.baseUrl}/query`, {org: this.api.organizationId}),
                body,
                args,
            ),
            args?.signal,
        );
    }

    queries<T extends readonly DataServiceQuery[]>(body: [...T], args?: RequestInit) {
        return patchAbort(
            this.api.post<
                DataServiceQueriesResponse<{
                    [I in keyof T]: T[I] extends DataServiceQuery<infer Y>
                        ? DataServiceQueryResponse<Y>
                        : DataServiceQueryResponse;
                }>
            >(super.buildPath(`${DataServiceResource.baseUrl}/queries`, {org: this.api.organizationId}), body, args),
            args?.signal,
        );
    }

    getTables(args?: RequestInit) {
        return patchAbort(
            this.api.get<DataServiceGetTablesResponse>(
                super.buildPath(`${DataServiceResource.baseUrl}/tables`, {org: this.api.organizationId}),
                args,
            ),
        );
    }
}
