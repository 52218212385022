import {PrivilegeModel} from '@core/api';

import {PrivilegesLists, privilegesListsIds} from '../constants/PrivilegesConstants';
import {PrivilegesListsState} from '../store/PrivilegesListStore';

const getPrivilegesList = (state: PrivilegesListsState, id: string): PrivilegeModel[] =>
    state?.privilegesLists?.[id] ?? [];

const getUserPrivilegesList = (state: PrivilegesListsState): PrivilegeModel[] =>
    getPrivilegesList(state, privilegesListsIds[PrivilegesLists.User]);

export const PrivilegesListSelector = {
    getPrivilegesList,
    getUserPrivilegesList,
};
