import {API, PageModel, Resource} from '@coveo/platform-client';
import {CreatePropertyOptions, PropertyResponseModel, PropertyModel, ListPropertyParams} from '../interfaces';

/**
 * The Properties resource is part of the Analytics Admin Service
 */
export class Properties extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/analyticsadmin/v1/properties`;

    /**
     * List all properties with pagination
     * @returns Promise<PageModel<PropertyModel>>
     */
    list(params?: ListPropertyParams): Promise<PageModel<PropertyModel>> {
        return this.api.get<PageModel<PropertyModel>>(this.buildPath(`${Properties.baseUrl}/list`, params));
    }

    /**
     * Get a property
     * @returns Promise<PropertyModel>
     */
    get({trackingId}): Promise<PropertyModel> {
        return this.api.get<PropertyModel>(this.buildPath(`${Properties.baseUrl}/${trackingId}`));
    }

    /**
     * Create a property
     * @returns Promise<PropertyResponseModel>
     */
    create({trackingId, ...rest}: CreatePropertyOptions): Promise<PropertyResponseModel> {
        return this.api.post<PropertyResponseModel>(this.buildPath(`${Properties.baseUrl}/${trackingId}`, {...rest}));
    }

    /**
     * Edit a property
     * @returns Promise<PropertyResponseModel>
     */
    edit({trackingId, ...rest}: CreatePropertyOptions): Promise<PropertyResponseModel> {
        return this.api.put<PropertyResponseModel>(this.buildPath(`${Properties.baseUrl}/${trackingId}`, {...rest}));
    }

    /**
     * Delete a property
     * @returns Promise<PropertyResponseModel>
     */
    delete({trackingId}): Promise<PropertyResponseModel> {
        return this.api.delete<PropertyResponseModel>(this.buildPath(`${Properties.baseUrl}/${trackingId}`));
    }
}
