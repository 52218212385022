import {Factory, factory, Title, TitleOrder, TitleProps, useProps} from '@coveord/plasma-mantine';
import {EmptyStateVariantType, useEmptyStateContext} from './EmptyStateContext';

export type EmptyStateTitleStylesNames = 'title';
export type EmptyStateTitleVariant = EmptyStateVariantType;

type EmptyStateTitleProps = TitleProps;

export type SelectFactory = Factory<{
    props: EmptyStateTitleProps;
    ref: HTMLHeadingElement;
    stylesNames: EmptyStateTitleStylesNames;
    variant: EmptyStateTitleVariant;
}>;
const defaultProps: Partial<EmptyStateTitleProps> = {};

export const EmptyStateTitle = factory<SelectFactory>((_props, ref) => {
    const ctx = useEmptyStateContext();
    const props = useProps('EmptyStateTitle', defaultProps, _props);
    const {className, classNames, styles, style, unstyled, children, ...others} = props;

    const titleSize: Record<EmptyStateVariantType, TitleOrder> = {
        small: 4,
        secondary: 3,
        primary: 2,
    };

    return (
        <Title
            ref={ref}
            order={titleSize[ctx.variant]}
            {...ctx.getStyles('title', {classNames, className, styles, style})}
            {...others}
        >
            {children}
            &nbsp;
            {ctx.docAnchor}
        </Title>
    );
});
