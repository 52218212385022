import {
    Box,
    BoxProps,
    factory,
    Factory,
    Group,
    Image,
    Paper,
    StylesApiProps,
    Text,
    Title,
    useProps,
} from '@coveord/plasma-mantine';
import {useEmptyStateContext} from './EmptyStateContext';

export type EmptyStateCardStylesNames = 'cardImage' | 'cardWrapper' | 'cardTitle' | 'cardBody' | 'cardDescription';

interface EmptyStateCardProps extends BoxProps, StylesApiProps<EmptyStateCardFactory> {
    title: string;
    description: string;
    image: any;
    onClick?: () => void;
}

export type EmptyStateCardFactory = Factory<{
    props: EmptyStateCardProps;
    ref: HTMLDivElement;
    stylesNames: EmptyStateCardStylesNames;
    compound: true;
}>;

const defaultProps: Partial<EmptyStateCardProps> = {};

export const EmptyStateCard = factory<EmptyStateCardFactory>((_props, ref) => {
    const ctx = useEmptyStateContext();
    const {title, description, image, onClick, classNames, styles, unstyled, ...others} = useProps(
        'EmptyStateCard',
        defaultProps,
        _props,
    );
    const stylesApiProps = {classNames, styles};

    return (
        <Box ref={ref} onClick={onClick} {...others}>
            <Paper
                p="md"
                radius={8}
                shadow="lg"
                withBorder
                {...ctx.getStyles('cardWrapper', stylesApiProps)}
                data-handler={!!onClick}
            >
                <Group gap="lg" wrap="nowrap" h="100%">
                    <Box {...ctx.getStyles('cardImage', stylesApiProps)}>
                        <Image fit="contain" w="100%" h="100%" src={image} />
                    </Box>
                    <Box {...ctx.getStyles('cardBody', stylesApiProps)}>
                        <Title order={4} mb="sm" {...ctx.getStyles('cardTitle', stylesApiProps)}>
                            {title}
                        </Title>
                        <Text size="sm" {...ctx.getStyles('cardDescription', stylesApiProps)}>
                            {description}
                        </Text>
                    </Box>
                </Group>
            </Paper>
        </Box>
    );
});
