import {
    Box,
    Image,
    ImageCssVariables,
    ImageProps,
    PolymorphicFactory,
    polymorphicFactory,
    useProps,
} from '@coveord/plasma-mantine';
import emptyStateImage from '../../images/empty-state.svg';
import {useEmptyStateContext} from './EmptyStateContext';

export type EmptyStateImageStylesNames = 'image';
type EmptyStateImageProps = ImageProps;

export type EmptyStateImageFactory = PolymorphicFactory<{
    props: EmptyStateImageProps;
    defaultComponent: 'img';
    defaultRef: HTMLImageElement;
    stylesNames: EmptyStateImageStylesNames;
    vars: ImageCssVariables;
    compound: true;
}>;

const defaultProps: Partial<EmptyStateImageProps> = {};

export const EmptyStateImage: ReturnType<typeof polymorphicFactory<EmptyStateImageFactory>> =
    polymorphicFactory<EmptyStateImageFactory>((_props, ref) => {
        const ctx = useEmptyStateContext();
        const {style, className, classNames, styles, unstyled, ...others} = useProps(
            'EmptyStateImage',
            defaultProps,
            _props,
        );

        return (
            <Box w="100%">
                <Image
                    ref={ref}
                    src={emptyStateImage}
                    fit="cover"
                    {...ctx.getStyles('image', {classNames, className, styles, style})}
                    {...others}
                />
            </Box>
        );
    });
