import {PageModel, Resource} from '@coveo/platform-client';
import {
    AvailablePipelineSetting,
    ListAvailablePipelineSettingsParams,
    PipelineSettingCreateRequest,
    PipelineSettingResponse,
    PipelineSettingUpdateRequest,
} from '../interfaces';

/**
 * API used to manager pipeline settings
 *
 * This API is documented in the internal Swagger documentation: https://platformdev.cloud.coveo.com/docs/internal?urls.primaryName=Search%20API#/Settings
 *
 * @private
 */
export class PipelineSettingsResource extends Resource {
    static baseUrl = `/rest/search/v2/admin/pipelines`;

    /**
     * List available query pipeline settings.
     *
     * @returns {Promise<PageModel<AvailablePipelineSettings>>} The list of available query pipeline settings.
     */
    listAvailableSettings(params?: ListAvailablePipelineSettingsParams): Promise<PageModel<AvailablePipelineSetting>> {
        return this.api.get<PageModel<AvailablePipelineSetting>>(
            this.buildPath(`${PipelineSettingsResource.baseUrl}/settings/metadata`, {
                ...params,
                organizationId: this.api.organizationId,
            }),
        );
    }
    /**
     * Read a setting for a specific pipeline.
     *
     * @param {string} pipelineId The unique identifier of the target query pipeline.
     * @param {string} settingsId The unique identifier of the query pipeline setting.
     *
     * @returns {Promise<PipelineSettingResponse>} The query pipeline setting.
     */
    get(pipelineId: string, settingsId: string): Promise<PipelineSettingResponse> {
        return this.api.get<PipelineSettingResponse>(
            this.buildPath(`${PipelineSettingsResource.baseUrl}/${pipelineId}/settings/${settingsId}`, {
                organizationId: this.api.organizationId,
            }),
        );
    }
    /**
     * Create a setting for a specific pipeline.
     *
     * @param {string}pipelineId The unique identifier of the target query pipeline.
     *
     * @returns {Promise<PipelineSettingResponse>} The created query pipeline setting.
     */
    create(pipelineId: string, request: PipelineSettingCreateRequest): Promise<PipelineSettingResponse> {
        return this.api.post<PipelineSettingResponse>(
            this.buildPath(`${PipelineSettingsResource.baseUrl}/${pipelineId}/settings`, {
                organizationId: this.api.organizationId,
            }),
            request,
        );
    }
    /**
     * Update a setting for a specific pipeline.
     *
     * @param {string} pipelineId The unique identifier of the target query pipeline.
     * @param {string} settingsId The unique identifier of the query pipeline setting.
     *
     * @returns {Promise<PipelineSettingResponse>} The updated query pipeline setting.
     */
    update(
        pipelineId: string,
        settingsId: string,
        request: PipelineSettingUpdateRequest,
    ): Promise<PipelineSettingResponse> {
        return this.api.put<PipelineSettingResponse>(
            this.buildPath(`${PipelineSettingsResource.baseUrl}/${pipelineId}/settings/${settingsId}`, {
                organizationId: this.api.organizationId,
            }),
            request,
        );
    }
}
