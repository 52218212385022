import {API, New, PageModel, Paginated, Resource} from '@coveo/platform-client';
import {
    SlotConfigurationModel,
    SlotConfigurationNameModel,
    SlotSettingsModel,
    SlotStrategiesModel,
    SlotsNamesOptions,
} from '../../interfaces';

export class SlotConfiguration extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/recommendations/slots`;

    list(options: Paginated = {}) {
        return this.api.get<PageModel<SlotConfigurationModel>>(this.buildPath(SlotConfiguration.baseUrl, options));
    }

    create(model: New<SlotConfiguration>) {
        return this.api.post<SlotConfigurationModel>(SlotConfiguration.baseUrl, model);
    }

    delete(configurationId: string) {
        return this.api.delete(`${SlotConfiguration.baseUrl}/${configurationId}`);
    }

    get(configurationId: string) {
        return this.api.get<SlotConfigurationModel>(`${SlotConfiguration.baseUrl}/${configurationId}`);
    }

    update(configurationId: string, model: SlotConfigurationModel) {
        return this.api.put<SlotConfiguration>(`${SlotConfiguration.baseUrl}/${configurationId}`, model);
    }

    getSlotSettings(configurationId: string) {
        return this.api.get<SlotSettingsModel>(`${SlotConfiguration.baseUrl}/${configurationId}/settings`);
    }

    updateSlotSettings(configurationId: string, model: SlotSettingsModel) {
        return this.api.put<SlotSettingsModel>(`${SlotConfiguration.baseUrl}/${configurationId}/settings`, model);
    }

    /**
     * Shows the available strategies to use for this slot in an organization.
     * @param configurationId
     * @returns SlotStrategiesModel
     */
    getSlotStrategies(configurationId: string) {
        return this.api.get<SlotStrategiesModel>(`${SlotConfiguration.baseUrl}/${configurationId}/strategies`);
    }

    /**
     * Retrive all slot configuration ids and names of an organization.
     * @param model SlotsNamesOptions
     * @returns PageModel<SlotConfigurationNameModel>
     */
    listNames(model: SlotsNamesOptions) {
        return this.api.get<PageModel<SlotConfigurationNameModel>>(
            this.buildPath(`${SlotConfiguration.baseUrl}/names`, model),
        );
    }
}
