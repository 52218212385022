import {BoxProps, Factory, factory, List, Stack, StylesApiProps, Text, useProps} from '@coveord/plasma-mantine';
import {Children, ReactElement, ReactNode} from 'react';
import {useEmptyStateContext} from '../EmptyStateContext';
import {EmptyStatePrerequisiteListItem} from './EmptyStatePrerequisiteListItem';

export type PrerequisiteListStylesName = 'prerequisiteList' | 'prerequisiteTitle' | 'prerequisiteListItemContainer';

export interface PrerequisiteListProps extends BoxProps, StylesApiProps<PrerequisiteListFactory> {
    /**
     * The prerequisite list title.
     */
    title: string;

    /**
     * The prerequisite items to display.
     */
    children: ReactNode;
}

export type PrerequisiteListFactory = Factory<{
    props: PrerequisiteListProps;
    ref: HTMLDivElement;
    stylesNames: PrerequisiteListStylesName;
    compound: true;
}>;

const defaultProps: Partial<PrerequisiteListProps> = {};

export const EmptyStatePrerequisiteList = factory<PrerequisiteListFactory>((_props, ref) => {
    const ctx = useEmptyStateContext();
    const props = useProps('EmptyStatePrerequisiteList', defaultProps, _props);
    const {title, children, classNames, className, styles, style} = props;

    const items = (Children.toArray(children) as ReactElement[]).filter(
        (child) => child.type === EmptyStatePrerequisiteListItem,
    );

    const stylesApiProps = {classNames, styles};

    return (
        <Stack ref={ref} {...ctx.getStyles('prerequisiteList', {...stylesApiProps, className, style})} gap="sm">
            <Text {...ctx.getStyles('prerequisiteTitle', stylesApiProps)}>{title}</Text>
            <List spacing="xs" center {...ctx.getStyles('prerequisiteListItemContainer', stylesApiProps)}>
                {items}
            </List>
        </Stack>
    );
});
