import {
    type ColumnDef,
    createColumnHelper,
    type Factory,
    factory,
    type StylesApiProps,
    Table,
    type TableProps,
    useForm,
    useProps,
    useStyles,
    useTable,
} from '@components/mantine';
import {ApiKeyAccessModel} from '@core/api';
import {useMemo} from 'react';
import {Locales} from '../../../strings/Locales';
import {AccessTableContextProvider} from '../AccessTable.context';
import {AccessTableData, AccessTableForm, AccessTableStylesNames} from '../AccessTable.types';
import {AccessTableAccessLevelCell} from '../AccessTableAccessLevelCell';
import {AccessTableUtils} from '../AccessTableUtils';

export interface APIKeysAccessTableProps
    extends Omit<TableProps<ApiKeyAccessModel>, 'data' | 'columns' | 'store' | 'classNames' | 'styles' | 'vars'>,
        StylesApiProps<APIKeysAccessTableFactory> {
    /**
     * The data to display in the table
     */
    data: ApiKeyAccessModel[];
    value: AccessTableData;
    onChange: (data: AccessTableData) => void;
}

export type APIKeysAccessTableFactory = Factory<{
    props: APIKeysAccessTableProps;
    ref: HTMLDivElement;
    stylesNames: AccessTableStylesNames;
    compound: true;
}>;

const defaultProps: Partial<APIKeysAccessTableProps> = {};

export const APIKeysAccessTable = factory<APIKeysAccessTableFactory>((_props, ref) => {
    const {data, value, onChange, classNames, styles, vars, className, style, ...others} = useProps(
        'APIKeysAccessTable',
        defaultProps,
        _props,
    );

    const sortedData = useMemo(() => AccessTableUtils.sortModels<ApiKeyAccessModel>(data), [JSON.stringify(data)]);

    const table = useTable<ApiKeyAccessModel>();
    const form: AccessTableForm = useForm({
        mode: 'uncontrolled',
        initialValues: {
            data: value,
        },
        onValuesChange: ({data: newData}) => {
            onChange(newData);
        },
    });

    const getStyles = useStyles<APIKeysAccessTableFactory>({
        name: 'APIKeysAccessTable',
        classes: {},
        vars,
        classNames,
        className,
        style,
        props: _props,
        styles,
    });

    return (
        <AccessTableContextProvider
            value={{
                getStyles,
                form,
            }}
        >
            <Table<ApiKeyAccessModel>
                ref={ref}
                store={table}
                data={sortedData}
                getRowId={(row) => row.id}
                columns={columns}
                {...others}
            />
        </AccessTableContextProvider>
    );
});

const columnHelper = createColumnHelper<ApiKeyAccessModel>();
const columns: Array<ColumnDef<ApiKeyAccessModel>> = [
    columnHelper.accessor('displayName', {
        header: Locales.format('APIKeysAccessTable.headerLabel.apiKeys'),
        enableSorting: false,
    }),
    columnHelper.accessor('accessLevel', {
        header: Locales.format('AccessTable.headerLabel.accessLevel'),
        enableSorting: false,
        cell: (info) => (
            <AccessTableAccessLevelCell id={info.row.original.id} accessLevel={info.getValue()} type="apiKey" />
        ),
    }),
];
