import {createSafeContext, CSSProperties, GetStylesApiOptions} from '@components/mantine';
import {AccessTableForm} from './AccessTable.types';
import {AccessTableStylesNames} from './AccessTable.types';

interface AccessTableContext {
    form: AccessTableForm;
    getStyles: (
        selector: AccessTableStylesNames,
        options?: GetStylesApiOptions,
    ) => {
        className: string;
        style: CSSProperties;
    };
}

export const [AccessTableContextProvider, useAccessTableContext] = createSafeContext<AccessTableContext>(
    'AccessTableContextProvider component was not found in the tree',
);
