import {
    Box,
    BoxProps,
    Button,
    Factory,
    factory,
    Group,
    Stack,
    StylesApiProps,
    useProps,
    useStyles,
} from '@coveord/plasma-mantine';
import {clsx} from 'clsx';
import {Children, ReactElement, ReactNode} from 'react';
import {EmptyStateBody, EmptyStateBodyStylesNames} from './EmptyStateBody';
import {EmptyStateCard, EmptyStateCardStylesNames} from './EmptyStateCard';
import {EmptyStateDocAnchor, EmptyStateDocAnchorStylesNames} from './EmptyStateDocAnchor';
import {EmptyStateImage, EmptyStateImageStylesNames} from './EmptyStateImage';
import {EmptyStateLink, EmptyStateLinkStylesNames} from './EmptyStateLink';
import {
    EmptyStatePrerequisiteList,
    EmptyStatePrerequisiteListItem,
    PrerequisiteItemStylesName,
    PrerequisiteListStylesName,
} from './empty-state-prerequisite';
import {EmptyStateTitle, EmptyStateTitleStylesNames} from './EmptyStateTitle';
import classes from './EmptyState.module.css';
import {EmptyStateProvider, EmptyStateVariantType} from './EmptyStateContext';

export type EmptyStateStylesNames =
    | 'root'
    | 'container'
    | 'actionsContainer'
    | EmptyStateBodyStylesNames
    | EmptyStateDocAnchorStylesNames
    | EmptyStateImageStylesNames
    | EmptyStateCardStylesNames
    | EmptyStateLinkStylesNames
    | EmptyStateTitleStylesNames
    | PrerequisiteListStylesName
    | PrerequisiteItemStylesName;

export interface EmptyStateProps extends BoxProps, StylesApiProps<EmptyStateFactory> {
    children: ReactNode;
    /**
     * The variant of the empty state.
     *
     * @default primary
     */
    variant?: EmptyStateVariantType;
}

export type EmptyStateFactory = Factory<{
    props: EmptyStateProps;
    ref: HTMLDivElement;
    stylesNames: EmptyStateStylesNames;
    variant: EmptyStateVariantType;
    vars: never;
    staticComponents: {
        Action: typeof Button;
        Title: typeof EmptyStateTitle;
        Body: typeof EmptyStateBody;
        Link: typeof EmptyStateLink;
        Image: typeof EmptyStateImage;
        Card: typeof EmptyStateCard;
        DocAnchor: typeof EmptyStateDocAnchor;
        PrerequisiteList: typeof EmptyStatePrerequisiteList;
        PrerequisiteListItem: typeof EmptyStatePrerequisiteListItem;
    };
}>;

const defaultProps: Partial<EmptyStateProps> = {
    variant: 'primary',
};

export const EmptyState = factory<EmptyStateFactory>((props, ref) => {
    const {variant, children, style, className, classNames, styles, unstyled, ...others} = useProps(
        'EmptyState',
        defaultProps,
        props,
    );
    const getStyles = useStyles<EmptyStateFactory>({
        name: 'EmptyState',
        classes,
        props,
        className,
        style,
        classNames,
        styles,
        unstyled,
    });
    const actions = (Children.toArray(children) as ReactElement[]).filter((child) => child.type === Button);
    const cards = (Children.toArray(children) as ReactElement[]).filter((child) => child.type === EmptyStateCard);
    const links = (Children.toArray(children) as ReactElement[]).filter((child) => child.type === EmptyStateLink);
    const image = (Children.toArray(children) as ReactElement[]).find((child) => child.type === EmptyStateImage);
    const title = (Children.toArray(children) as ReactElement[]).find((child) => child.type === EmptyStateTitle);
    const body = (Children.toArray(children) as ReactElement[]).find((child) => child.type === EmptyStateBody);
    const docAnchor = (Children.toArray(children) as ReactElement[]).find(
        (child) => child.type === EmptyStateDocAnchor,
    );

    if (variant === 'small') {
        return (
            <EmptyStateProvider value={{variant, docAnchor, getStyles}}>
                <Box ref={ref} variant={variant} {...others} {...getStyles('root')}>
                    {title}
                    <Box className={clsx({[classes.container]: !unstyled})} p="lg">
                        {image}
                        {body}
                        {links}
                        {actions}
                    </Box>
                </Box>
            </EmptyStateProvider>
        );
    }

    return (
        <EmptyStateProvider value={{variant, docAnchor, getStyles}}>
            <Group
                ref={ref}
                p="xl"
                wrap="nowrap"
                justify="center"
                gap="xl"
                variant={variant}
                {...others}
                {...getStyles('root')}
            >
                <Box {...getStyles('container')}>
                    {title}
                    {body}
                    {links.length ? (
                        <Stack gap="xs" mb="lg">
                            {links}
                        </Stack>
                    ) : null}
                    <Group {...getStyles('actionsContainer')} gap="sm">
                        {actions}
                    </Group>
                    {cards.length ? <Group gap="xl">{cards}</Group> : null}
                </Box>
                {image}
            </Group>
        </EmptyStateProvider>
    );
});

EmptyState.Action = Button;
EmptyState.Title = EmptyStateTitle;
EmptyState.Body = EmptyStateBody;
EmptyState.Link = EmptyStateLink;
EmptyState.Image = EmptyStateImage;
EmptyState.Card = EmptyStateCard;
EmptyState.DocAnchor = EmptyStateDocAnchor;
EmptyState.PrerequisiteList = EmptyStatePrerequisiteList;
EmptyState.PrerequisiteListItem = EmptyStatePrerequisiteListItem;
EmptyState.displayName = '@components/mantine/EmptyState';
