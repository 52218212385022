import {API, Resource} from '@coveo/platform-client';
import {AnswerConfiguration, AnswerPage, AnswerPagingParams} from '../../../interfaces/knowledge/AnswerInterfaces';

export class AnswerConfigurationResource extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/answer/v1/configs`;

    list(options?: AnswerPagingParams): Promise<AnswerPage<AnswerConfiguration>> {
        return this.api.get<AnswerPage<AnswerConfiguration>>(
            this.buildPath(AnswerConfigurationResource.baseUrl, options),
        );
    }
}
