import {PrivilegeModel} from '@core/api';

import {Config} from '@core/configuration';

export const PrivilegeTypes = {
    View: 'VIEW',
    Edit: 'EDIT',
    Create: 'CREATE',
    Allow: 'ALLOW', // Type is simply not defined for "Allow" privileges on platform side.
};

export const PrivilegeLevels = {
    Normal: 'NORMAL',
    Internal: 'INTERNAL',
    Global: 'GLOBAL',
};

export const PrivilegesAccessOptions = {
    None: 'NONE',
    View: 'VIEW',
    Edit: 'EDIT',
    Can: 'ALLOWED',
    Cannot: 'DISABLE',
    Push: 'PUSH',
    PushAndView: 'PUSH_AND_VIEW',
    EditAll: 'EDIT_ALL',
    ViewAll: 'VIEW_ALL',
    Custom: 'CUSTOM',
    Create: 'CREATE',
};

export const PrivilegeOwners = {
    Platform: 'PLATFORM',
    UsageAnalytics: 'USAGE_ANALYTICS',
    SearchApi: 'SEARCH_API',
    MachineLearning: 'COVEO_ML',
    Commerce: 'COMMERCE',
    Service: 'CUSTOMER_SERVICE',
};

export const SOPrivilegeDomains = {
    Administrate: 'ADMINISTRATE',
    AuthenticationEditor: 'AUTHENTICATION_EDITOR',
    ExecuteQuery: 'EXECUTE_QUERY',
    Impersonate: 'IMPERSONATE',
    QueryPipeline: 'QUERY_PIPELINE',
    QueryPipelineScriptEditor: 'QUERY_PIPELINE_SCRIPT_EDITOR',
    SalesforceAuthentication: 'SALESFORCE_AUTHENTICATION',
    SearchPages: 'SEARCH_PAGES',
    ViewAllContent: 'VIEW_ALL_CONTENT',
    ReplayAnyQuery: 'REPLAY_ANY_QUERY',
};

export const MLPrivilegeDomains = {
    MachineLearningModels: 'MODELS',
    AllowContentPreview: 'ALLOW_CONTENT_PREVIEW',
};

export const UAPrivilegeDomains = {
    Administrate: 'ADMINISTRATE',
    Impersonate: 'IMPERSONATE',
    Exports: 'EXPORTS',
    AnalyticsData: 'ANALYTICS_DATA',
    Reports: 'REPORTS',
    CustomDimensions: 'CUSTOM_DIMENSIONS',
    NamedFilters: 'NAMED_FILTERS',
    PermissionFilters: 'PERMISSION_FILTERS',
    ViewAllReports: 'VIEW_ALL_REPORTS',
    MetricAlerts: 'METRIC_ALERTS',
    DeleteUserData: 'DELETE_USER_ANALYTICS_DATA',
    SnowflakeReaderAccounts: 'SNOWFLAKE_READER_ACCOUNTS',
};

export const ABPrivilegeDomains = {
    Activities: 'ACTIVITIES',
    Snapshots: 'SNAPSHOTS',
};

export const CommercePrivilegeDomains = {
    Catalog: 'CATALOG',
    ProductListing: 'PRODUCT_LISTING',
    MerchandisingHub: 'MERCHANDISING_HUB',
};

export const ServicePrivilegeDomains = {
    CaseAssist: 'CASE_ASSIST_CONFIGURATION',
    InsightPanel: 'INSIGHT_PANEL_CONFIGURATION',
    InsightPanelInterface: 'INSIGHT_PANEL_INTERFACE',
    UseCaseAssist: 'USE_CASE_ASSIST',
    KnowledgeHub: 'KNOWLEDGE_HUB',
};

export const PrivilegeDomains = {
    ...UAPrivilegeDomains,
    ...SOPrivilegeDomains,
    ...ABPrivilegeDomains,
    ...MLPrivilegeDomains,
    ...CommercePrivilegeDomains,
    ...ServicePrivilegeDomains,
    ApiKey: 'API_KEY',
    Source: 'SOURCE',
    Field: 'FIELD',
    SecurityCache: 'SECURITY_CACHE',
    SecurityProvider: 'SECURITY_PROVIDER',
    Group: 'GROUP',
    GlobalGroup: 'GLOBAL_GROUP',
    Organization: 'ORGANIZATION',
    OrganizationAdministration: 'ORGANIZATION_ADMINISTRATION',
    OnPremiseAdministration: 'ON_PREMISE_ADMINISTRATION',
    InternalUser: 'INTERNAL_USER',
    Ops: 'OPS',
    Index: 'INDEX',
    ElasticIndex: 'ELASTIC_INDEX',
    Infrastructure: 'INFRASTRUCTURE',
    ManageLicense: 'MANAGE_LICENSE',
    ManageCustomerLicense: 'MANAGE_CUSTOMER_LICENSE',
    ManageCustomerLicenseOverrides: 'MANAGE_CUSTOMER_LICENSE_OVERRIDES',
    Extensions: 'INDEXING_PIPELINE_EXTENSION',
    SAMLIdentityProvider: 'SAML_IDENTITY_PROVIDER',
    OrganizationTemplate: 'ORGANIZATION_TEMPLATE',
    Subscription: 'SUBSCRIPTION',
    OrganizationsExport: 'ORGANIZATIONS_EXPORT',
    SearchHubs: 'SEARCH_USAGE_METRICS',
    QueryLogs: 'QUERY_LOGS',
    LogicalIndex: 'LOGICAL_INDEX',
    TemporaryAccess: 'TEMPORARY_ACCESS',
    TemporaryAccessRequest: 'TEMPORARY_ACCESS_REQUEST',
    CriticalUpdates: 'CRITICAL_UPDATE',
    CrawlingModules: 'CRAWLING_MODULE',
    ResourceSnapshots: 'SNAPSHOTS',
    Link: 'LINK',
    SourceMetadata: 'SOURCE_METADATA',
    ConnectivityDiagnosticLogs: 'CONNECTIVITY_DIAGNOSTIC_LOGS',
    CrawlingModuleLogRequest: 'CRAWLING_MODULE_LOG_REQUEST',
    Project: 'PROJECTS',
    TrackingId: 'TRACKING_ID',
};

export enum PrivilegesLists {
    Groups,
    ApiKeys,
    GlobalGroups,
    User,
    Other,
}

const organizationUrl = `${Config.CloudPlatform.url}/rest/organizations/{organizationName}`;

export const privilegesListsIds = {
    [PrivilegesLists.Groups]: 'organization-privileges',
    [PrivilegesLists.GlobalGroups]: 'global-groups-privileges',
    [PrivilegesLists.ApiKeys]: 'apikeys-privileges',
    [PrivilegesLists.User]: 'user-privileges',
};

export const privilegesListsUrls = {
    [PrivilegesLists.Groups]: `${organizationUrl}/privileges`,
    [PrivilegesLists.GlobalGroups]: `${Config.CloudPlatform.url}/rest/globalprivileges`,
    [PrivilegesLists.ApiKeys]: `${organizationUrl}/privileges/apikeys`,
    [PrivilegesLists.User]: `${organizationUrl}/members/privileges`,
};

export type PrivilegesListMap = Record<string, PrivilegeModel[]>;
const allTargetIds = '*';
const granularDomains = {
    Sources: `${PrivilegeDomains.Source}_${PrivilegeOwners.Platform}_${PrivilegeLevels.Normal}`,
    Groups: `${PrivilegeDomains.Group}_${PrivilegeOwners.Platform}_${PrivilegeLevels.Normal}`,
    ApiKeys: `${PrivilegeDomains.ApiKey}_${PrivilegeOwners.Platform}_${PrivilegeLevels.Normal}`,
    Extensions: `${PrivilegeDomains.Extensions}_${PrivilegeOwners.Platform}_${PrivilegeLevels.Normal}`,
    Pipelines: `${PrivilegeDomains.QueryPipeline}_${PrivilegeOwners.SearchApi}_${PrivilegeLevels.Normal}`,
    SearchPages: `${PrivilegeDomains.SearchPages}_${PrivilegeOwners.SearchApi}_${PrivilegeLevels.Normal}`,
};
const potentPrivileges: PrivilegeModel[] = [
    {
        owner: PrivilegeOwners.SearchApi,
        targetDomain: PrivilegeDomains.ExecuteQuery,
        targetId: allTargetIds,
    },
    {
        owner: PrivilegeOwners.SearchApi,
        targetDomain: PrivilegeDomains.Impersonate,
        targetId: allTargetIds,
    },
    {
        owner: PrivilegeOwners.SearchApi,
        targetDomain: PrivilegeDomains.ViewAllContent,
        targetId: allTargetIds,
    },
    {
        owner: PrivilegeOwners.UsageAnalytics,
        targetDomain: PrivilegeDomains.Administrate,
        targetId: allTargetIds,
    },
    {
        owner: PrivilegeOwners.UsageAnalytics,
        targetDomain: PrivilegeDomains.ViewAllReports,
        targetId: allTargetIds,
    },
    {
        owner: PrivilegeOwners.UsageAnalytics,
        targetDomain: PrivilegeDomains.DeleteUserData,
        targetId: allTargetIds,
    },
    {
        owner: PrivilegeOwners.Platform,
        targetDomain: PrivilegeDomains.OnPremiseAdministration,
        targetId: allTargetIds,
        type: PrivilegeTypes.View,
    },
    {
        owner: PrivilegeOwners.Platform,
        targetDomain: PrivilegeDomains.OnPremiseAdministration,
        targetId: allTargetIds,
        type: PrivilegeTypes.Edit,
    },
    {
        owner: PrivilegeOwners.Platform,
        targetDomain: PrivilegeDomains.Group,
        targetId: allTargetIds,
        type: PrivilegeTypes.Edit,
    },
    {
        owner: PrivilegeOwners.Platform,
        targetDomain: PrivilegeDomains.SourceMetadata,
        targetId: allTargetIds,
        type: PrivilegeTypes.View,
    },
    {
        owner: PrivilegeOwners.Platform,
        targetDomain: PrivilegeDomains.CrawlingModuleLogRequest,
        targetId: allTargetIds,
        type: PrivilegeTypes.Create,
    },
    {
        owner: PrivilegeOwners.Platform,
        targetDomain: PrivilegeDomains.CrawlingModuleLogRequest,
        targetId: allTargetIds,
        type: PrivilegeTypes.View,
    },
];
/**
 * Privileges that causes "Restrictive" options in the privileges table.
 * this behavior prevents the user from creating a faulty combination.
 */
const restrictivePrivileges: PrivilegeModel[] = [
    {
        owner: PrivilegeOwners.SearchApi,
        targetDomain: PrivilegeDomains.ExecuteQuery,
        targetId: allTargetIds,
    },
    {
        owner: PrivilegeOwners.SearchApi,
        targetDomain: PrivilegeDomains.Impersonate,
        targetId: allTargetIds,
    },
];

export const PrivilegesConstants = {
    allTargetIds,
    granularDomains,
    potentPrivileges,
    restrictivePrivileges,
};
