import {Prompt, Text} from '@components/mantine';
import {Locales} from '../../../strings/Locales';
import {useAccessTableContext} from '../AccessTable.context';
import {useGroupsAccessTableContext} from './GroupsAccessTable.context';

export const LoseAccessPrompt = () => {
    const {form} = useAccessTableContext();
    const {previousData, setIsLoseAccessAcknowledged, isLoseAccessPromptOpened, closeLoseAccessPrompt} =
        useGroupsAccessTableContext();
    const loseEditorAccess = (): void => {
        setIsLoseAccessAcknowledged(true);
        closeLoseAccessPrompt();
    };

    const undoChange = (): void => {
        if (previousData !== null) {
            form.setValues({data: previousData});
        }

        closeLoseAccessPrompt();
    };

    return (
        <Prompt
            variant="warning"
            opened={isLoseAccessPromptOpened}
            title={Locales.format('GroupsAccessTable.LoseAccessPrompt.title')}
            onClose={undoChange}
            centered
        >
            <Text size="sm" pb="sm">
                {Locales.format('GroupsAccessTable.LoseAccessPrompt.description')}
            </Text>

            <Prompt.Footer>
                <Prompt.CancelButton onClick={undoChange}>
                    {Locales.format('GroupsAccessTable.LoseAccessPrompt.UndoChange')}
                </Prompt.CancelButton>
                <Prompt.ConfirmButton onClick={loseEditorAccess}>
                    {Locales.format('GroupsAccessTable.LoseAccessPrompt.LoseEditorAccess')}
                </Prompt.ConfirmButton>
            </Prompt.Footer>
        </Prompt>
    );
};
