import {ContextType, useContext} from 'react';

import {GuardContext} from '../contexts';

export const useGuard = (): ContextType<typeof GuardContext> => {
    const guardContext = useContext(GuardContext);
    if (guardContext === undefined) {
        throw new Error('useGuard hook must be used within a Guard component.');
    }
    return guardContext;
};
