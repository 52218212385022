import {
    Box,
    type ColumnDef,
    createColumnHelper,
    type Factory,
    factory,
    type StylesApiProps,
    Table,
    type TableProps,
    Tooltip,
    useDisclosure,
    useForm,
    useProps,
    useStyles,
    useTable,
} from '@components/mantine';
import {AccessLevel, GroupAccessModel} from '@core/api';
import {CheckSize16Px, CrossSize16Px} from '@coveord/plasma-react-icons';
import {FunctionComponent, useMemo, useState} from 'react';
import {useGuard} from '../../../hooks';
import {Locales} from '../../../strings/Locales';
import {AccessTableContextProvider} from '../AccessTable.context';
import {AccessTableData, AccessTableForm, AccessTableStylesNames} from '../AccessTable.types';
import {AccessTableAccessLevelCell} from '../AccessTableAccessLevelCell';
import {AccessTableUtils} from '../AccessTableUtils';
import {GroupsAccessTableContextProvider, useGroupsAccessTableContext} from './GroupsAccessTable.context';
import classes from './GroupsAccessTable.module.css';
import {GroupsAccessTableUtils} from './GroupsAccessTableUtils';
import {LoseAccessPrompt} from './LoseAccessPrompt';

export type GroupsAccessTableStylesNames =
    | AccessTableStylesNames
    | 'membershipTooltip'
    | 'iconMember'
    | 'iconNotMember';

export interface GroupsAccessTableProps
    extends Omit<TableProps<GroupAccessModel>, 'data' | 'columns' | 'store' | 'classNames' | 'styles' | 'vars'>,
        StylesApiProps<GroupsAccessTableFactory> {
    /**
     * The data to display in the table
     */
    data: GroupAccessModel[];
    value: AccessTableData;
    onChange: (data: AccessTableData) => void;
    canEditAll: boolean;
}

export type GroupsAccessTableFactory = Factory<{
    props: GroupsAccessTableProps;
    ref: HTMLDivElement;
    stylesNames: GroupsAccessTableStylesNames;
    compound: true;
}>;

const defaultProps: Partial<GroupsAccessTableProps> = {};

export const GroupsAccessTable = factory<GroupsAccessTableFactory>((_props, ref) => {
    const {canEdit} = useGuard();
    const {data, value, onChange, canEditAll, classNames, styles, vars, className, style, ...others} = useProps(
        'GroupsAccessTable',
        defaultProps,
        _props,
    );

    const sortedData = useMemo(() => AccessTableUtils.sortModels<GroupAccessModel>(data), [JSON.stringify(data)]);

    const [previousData, setPreviousData] = useState<AccessTableData | null>(null);
    const [isLoseAccessAcknowledged, setIsLoseAccessAcknowledged] = useState(false);
    const [isLoseAccessPromptOpened, {open: openLoseAccessPrompt, close: closeLoseAccessPrompt}] = useDisclosure(false);

    const table = useTable<GroupAccessModel>();
    const form: AccessTableForm = useForm({
        mode: 'uncontrolled',
        initialValues: {
            data: value,
        },
        onValuesChange: ({data: newData}, {data: oldData}) => {
            setPreviousData({...oldData});

            if (newData) {
                let partOfGroupThatCanEdit = false;
                const groupsThatCanBeModifySetToEdit = Object.entries(newData).filter(
                    ([key, accessLevel]) =>
                        accessLevel === AccessLevel.EDIT_ALL && GroupsAccessTableUtils.isPartOfGroup(data, key),
                );

                if (groupsThatCanBeModifySetToEdit.length !== 0) {
                    partOfGroupThatCanEdit = true;
                    setIsLoseAccessAcknowledged(false);
                }

                if (canEdit && !canEditAll && !partOfGroupThatCanEdit && !isLoseAccessAcknowledged) {
                    openLoseAccessPrompt();
                }
            }
            onChange(newData);
        },
    });

    const getStyles = useStyles<GroupsAccessTableFactory>({
        name: 'GroupsAccessTable',
        classes,
        vars,
        classNames,
        className,
        style,
        props: _props,
        styles,
    });

    return (
        <AccessTableContextProvider value={{getStyles, form}}>
            <GroupsAccessTableContextProvider
                value={{
                    getStyles,
                    previousData,
                    isLoseAccessAcknowledged,
                    setIsLoseAccessAcknowledged,
                    isLoseAccessPromptOpened,
                    openLoseAccessPrompt,
                    closeLoseAccessPrompt,
                }}
            >
                <LoseAccessPrompt />
                <Table<GroupAccessModel>
                    ref={ref}
                    store={table}
                    data={sortedData}
                    getRowId={(row) => row.id}
                    columns={columns}
                    {...others}
                />
            </GroupsAccessTableContextProvider>
        </AccessTableContextProvider>
    );
});

const columnHelper = createColumnHelper<GroupAccessModel>();
const columns: Array<ColumnDef<GroupAccessModel>> = [
    columnHelper.accessor('displayName', {
        header: Locales.format('GroupsAccessTable.headerLabel.group'),
        enableSorting: false,
    }),
    columnHelper.accessor('callerPartOf', {
        header: Locales.format('GroupsAccessTable.headerLabel.membership'),
        cell: (info) => <MembershipCell callerPartOf={info.getValue()} />,
        enableSorting: false,
    }),
    columnHelper.accessor('accessLevel', {
        header: Locales.format('AccessTable.headerLabel.accessLevel'),
        enableSorting: false,
        cell: (info) => (
            <AccessTableAccessLevelCell id={info.row.original.id} accessLevel={info.getValue()} type="group" />
        ),
    }),
];

const MembershipCell: FunctionComponent<Pick<GroupAccessModel, 'callerPartOf'>> = ({callerPartOf}) => {
    const {getStyles} = useGroupsAccessTableContext();
    return (
        <Tooltip
            label={Locales.format(
                callerPartOf
                    ? 'GroupsAccessTable.tooltip.callerPartOf_yes'
                    : 'GroupsAccessTable.tooltip.callerPartOf_no',
            )}
            position="top-start"
            {...getStyles('membershipTooltip')}
        >
            <Box>
                {callerPartOf ? (
                    <CheckSize16Px height={16} className={classes.iconMember} {...getStyles('iconMember')} />
                ) : (
                    <CrossSize16Px height={16} className={classes.iconNotMember} {...getStyles('iconNotMember')} />
                )}
            </Box>
        </Tooltip>
    );
};
