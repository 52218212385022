import {API, Resource} from '@coveo/platform-client';
import {
    ProductListingMetricsBaseModel,
    ProductListingMetricsPageModel,
    ProductListingMetricsProductPageModel,
    MetricsOptionsWithPagination,
    MetricsOptions,
} from '../../interfaces';

export class ProductListingReportingMetrics extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/reports/v1/productListings`;

    get(productListingId: string, options?: MetricsOptions) {
        return this.api.get<ProductListingMetricsBaseModel>(
            this.buildPath(`${ProductListingReportingMetrics.baseUrl}/${productListingId}`, options),
        );
    }

    getProducts(productListingId: string, options?: MetricsOptionsWithPagination) {
        return this.api.get<ProductListingMetricsProductPageModel>(
            this.buildPath(`${ProductListingReportingMetrics.baseUrl}/${productListingId}/products`, options),
        );
    }

    list(options?: MetricsOptionsWithPagination): Promise<ProductListingMetricsPageModel> {
        return this.api.get<ProductListingMetricsPageModel>(
            this.buildPath(`${ProductListingReportingMetrics.baseUrl}`, options),
        );
    }
}
