import {
    AnchorCssVariables,
    AnchorProps,
    AnchorVariant,
    factory,
    Factory,
    StylesApiProps,
    Tooltip,
    useProps,
} from '@coveord/plasma-mantine';
import {QuestionSize16Px} from '@coveord/plasma-react-icons';
import {Anchor} from '../anchor';
import {useEmptyStateContext} from './EmptyStateContext';

export type EmptyStateDocAnchorStylesNames = 'docAnchor';

interface EmptyStateDocAnchorProps
    extends Omit<AnchorProps, 'styles' | 'classNames' | 'vars'>,
        StylesApiProps<EmptyStateDocAnchorFactory> {
    href: string;
    label?: string;
}

export type EmptyStateDocAnchorFactory = Factory<{
    props: EmptyStateDocAnchorProps;
    ref: HTMLAnchorElement;
    stylesNames: EmptyStateDocAnchorStylesNames;
    vars: AnchorCssVariables;
    variant: AnchorVariant;
    compound: true;
}>;

const defaultProps: Partial<EmptyStateDocAnchorProps> = {};

export const EmptyStateDocAnchor = factory<EmptyStateDocAnchorFactory>((_props, ref) => {
    const ctx = useEmptyStateContext();
    const props = useProps('EmptyStateDocAnchor', defaultProps, _props);
    const {label, className, classNames, styles, vars: _vars, style, unstyled: _unstyled, ...others} = props;

    return (
        <Tooltip label={label} disabled={!label} position="right">
            <Anchor
                inline
                ref={ref}
                ml="xs"
                {...ctx.getStyles('docAnchor', {classNames, className, styles, style})}
                {...others}
            >
                <QuestionSize16Px height={16} />
            </Anchor>
        </Tooltip>
    );
});
