import {AccessLevel, AccessModel, PrivilegeHolderType} from '@core/api';

const sortByCallerPartOfAndByName = (a: AccessModel, b: AccessModel): number => {
    let featuredWeight = 0;
    if (a.privilegeHolderType === PrivilegeHolderType.GROUP && b.privilegeHolderType === PrivilegeHolderType.GROUP) {
        if (a.callerPartOf > b.callerPartOf) {
            featuredWeight = -10;
        }
        if (a.callerPartOf < b.callerPartOf) {
            featuredWeight = 10;
        }
    }
    const displayNameA = a.displayName?.toLowerCase() || '';
    const displayNameB = b.displayName?.toLowerCase() || '';
    if (displayNameA < displayNameB) {
        return -1 + featuredWeight;
    }
    if (displayNameA > displayNameB) {
        return 1 + featuredWeight;
    }

    return featuredWeight;
};

const isFeatured = (accessModel: AccessModel) =>
    [AccessLevel.VIEW_ALL, AccessLevel.CUSTOM].includes(accessModel.accessLevel);

const sortModels = <T extends AccessModel = AccessModel>(accessModels: T[]): T[] => {
    const featuredAccessModel = accessModels.filter(isFeatured).sort(sortByCallerPartOfAndByName);
    const restAccessModel = accessModels
        .filter((accessModel) => !isFeatured(accessModel))
        .sort(sortByCallerPartOfAndByName);

    return [...featuredAccessModel, ...restAccessModel];
};
export const AccessTableUtils = {
    sortModels,
};
