import {API, New, PageModel, Resource} from '@coveo/platform-client';
import {
    ListingConfigurationModel,
    ListingConfigurationNameModel,
    ProductListingsListNamesOptions,
    PartialListingConfigurationModel,
    ProductListingsListOptionsV2,
} from '../../interfaces';

export class ProductListingConfiguration extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/configurations/listings`;

    list(options?: ProductListingsListOptionsV2) {
        return this.api.get<PageModel<ListingConfigurationModel>>(
            this.buildPath(ProductListingConfiguration.baseUrl, options),
        );
    }

    // Like list(), but returns only basic information about the listing configuration (id, name, trackingId, patterns)
    partial(options?: ProductListingsListOptionsV2) {
        return this.api.get<PageModel<PartialListingConfigurationModel>>(
            this.buildPath(`${ProductListingConfiguration.baseUrl}/partial`, options),
        );
    }

    create(model: New<ListingConfigurationModel>) {
        return this.api.post<ListingConfigurationModel>(ProductListingConfiguration.baseUrl, model);
    }

    delete(configurationId: string) {
        return this.api.delete(`${ProductListingConfiguration.baseUrl}/${configurationId}`);
    }

    update(configurationId: string, model: ListingConfigurationModel) {
        return this.api.put<ListingConfigurationModel>(
            `${ProductListingConfiguration.baseUrl}/${configurationId}`,
            model,
        );
    }

    get(configurationId: string) {
        return this.api.get<ListingConfigurationModel>(`${ProductListingConfiguration.baseUrl}/${configurationId}`);
    }

    getGlobal(trackingId: string) {
        return this.api.get<ListingConfigurationModel>(
            this.buildPath(`${ProductListingConfiguration.baseUrl}/global`, {trackingId}),
        );
    }

    listNames(model: ProductListingsListNamesOptions) {
        return this.api.get<PageModel<ListingConfigurationNameModel>>(
            this.buildPath(`${ProductListingConfiguration.baseUrl}/names`, model),
        );
    }
}
