import {createSafeContext, type GetStylesApi} from '@components/mantine';
import {Dispatch} from 'react';
import {AccessTableData} from '../AccessTable.types';
import {GroupsAccessTableFactory} from './GroupsAccessTable';

interface GroupsAccessTableContext {
    getStyles: GetStylesApi<GroupsAccessTableFactory>;
    previousData: AccessTableData;
    isLoseAccessAcknowledged: boolean;
    setIsLoseAccessAcknowledged: Dispatch<boolean>;
    isLoseAccessPromptOpened: boolean;
    openLoseAccessPrompt: () => void;
    closeLoseAccessPrompt: () => void;
}

export const [GroupsAccessTableContextProvider, useGroupsAccessTableContext] =
    createSafeContext<GroupsAccessTableContext>('GroupsAccessTable component was not found in the tree');
