import {Resource} from '@coveo/platform-client';
import {ExportVisitsWithBodyParams, ExportWithBodyParams, ExportWithBodyResponse} from '../interfaces';

export class UAExportsResource extends Resource {
    static baseUrl = '/rest/ua/v15/exports';

    exportWithBody(params: ExportWithBodyParams, args?: RequestInit) {
        return this.api.post<ExportWithBodyResponse>(
            this.buildPathWithOrg(`${UAExportsResource.baseUrl}/create`),
            params,
            args,
        );
    }

    exportVisitsWithBody(params: ExportVisitsWithBodyParams, args?: RequestInit) {
        return this.api.post<ExportWithBodyResponse>(
            this.buildPathWithOrg(`${UAExportsResource.baseUrl}/visits/create`),
            params,
            args,
        );
    }

    /**
     * Build the request path, handling the optional `org` query parameter.
     *
     * @param route The path part of the request.
     * @param queryParams Optional query parameters object.
     * If this object contains an `org` property, it will override the value from the configuration.
     * @returns The request path including formatted query parameters.
     */
    protected buildPathWithOrg(route: string, queryParams?: any): string {
        return super.buildPath(route, {org: this.api.organizationId, ...queryParams});
    }
}
