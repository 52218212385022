import {API, Resource} from '@coveo/platform-client';
import {
    SearchMetricsModel,
    SearchMetricsPageModel,
    SearchMetricsProductPageModel,
    MetricsOptions,
    MetricsOptionsWithPagination,
} from '../../interfaces';

export class SearchMetrics extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/reports/v1/queries`;

    /**
     * Get search metrics by organization
     * @param options
     * @returns SearchMetricsPageModel
     */
    list(options?: Partial<MetricsOptionsWithPagination>): Promise<SearchMetricsPageModel> {
        return this.api.get<SearchMetricsPageModel>(this.buildPath(SearchMetrics.baseUrl, options));
    }

    /**
     * Get search expression performance metrics
     * @param expressionId
     * @param options
     * @returns SearchMetricsModel
     */
    get(expressionId: string, options?: Partial<MetricsOptions>): Promise<SearchMetricsModel> {
        return this.api.get<SearchMetricsModel>(this.buildPath(`${SearchMetrics.baseUrl}/${expressionId}`, options));
    }

    /**
     * Get search expression product metrics
     * @param expressionId
     * @param options
     * @returns SearchMetricsProductPageModel
     */
    getProducts(
        expressionId: string,
        options?: Partial<MetricsOptionsWithPagination>,
    ): Promise<SearchMetricsProductPageModel> {
        return this.api.get<SearchMetricsProductPageModel>(
            this.buildPath(`${SearchMetrics.baseUrl}/${expressionId}/products`, options),
        );
    }
}
