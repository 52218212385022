import {Resource} from '@coveo/platform-client';
import {
    DataHealthGetFailedInstancesParams,
    DataHealthGetFailedInstancesResponse,
} from '../interfaces/UAReadDataHealthInterfaces';

/**
 * Workaround for platform-client resolving with `undefined` on abort.
 *
 * @param fetchPromise The promise returned by the API.
 * @param signal The signal passed to the API.
 */
const patchAbort = async <T>(fetchPromise: Promise<T>, signal?: AbortSignal | null): Promise<T> => {
    const value = await fetchPromise;
    if (value === undefined) {
        // It is possible platform-client aborted via abortGetRequests().
        // In that case the reason can't be accessed; new an Error instead.
        throw signal?.reason || new Error('Request was aborted');
    }
    return value;
};

/**
 * Definitions for the pending DataHealth API.
 * Technically this is not a separate resource, rather it is part of UA Read Service.
 */
export class UAReadDataHealthResource extends Resource {
    static baseUrl = '/rest/ua/v15/datahealth';

    getFailedInstances(params: DataHealthGetFailedInstancesParams, args?: RequestInit) {
        return patchAbort(
            this.api.get<DataHealthGetFailedInstancesResponse>(
                this.buildPathWithOrg(`${UAReadDataHealthResource.baseUrl}/criteria/failedInstances`, params),
                args,
            ),
            args?.signal,
        );
    }
    /**
     * Build the request path, handling the optional `org` query parameter.
     *
     * @param route The path part of the request.
     * @param queryParams Optional query parameters object.
     * If this object contains an `org` property, it will override the value from the configuration.
     * @returns The request path including formatted query parameters.
     */
    protected buildPathWithOrg(route: string, queryParams?: any): string {
        return super.buildPath(route, {org: this.api.organizationId, ...queryParams});
    }
}
