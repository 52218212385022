import {createSafeContext, type GetStylesApi} from '@coveord/plasma-mantine';
import type {ReactNode} from 'react';
import type {EmptyStateFactory} from './EmptyState';

export type EmptyStateVariantType = 'primary' | 'secondary' | 'small';

interface EmptyStateContextType {
    getStyles: GetStylesApi<EmptyStateFactory>;
    variant: EmptyStateVariantType;
    docAnchor: ReactNode;
}

export const [EmptyStateProvider, useEmptyStateContext] = createSafeContext<EmptyStateContextType>(
    'EmptyState component was not found in tree',
);
